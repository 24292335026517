import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { AfterContentInit, Component, inject, Input, OnInit } from '@angular/core';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { ControlValueAccessor } from '@angular/forms';
import { PlatformService } from '../../services/platform.service';
import { ModuleService } from '../../services/module.service';
import { FeatureService } from '../../services/feature.service';
import { Feature } from '../../models/feature.model';
import { Platform } from '../../models/platform.model';
import { Module } from '../../models/module.model';

@Component({
  standalone: false,
  selector: 'peca-feature-composition-form[features]',
  templateUrl: './feature-composition-form.component.html',
  styleUrls: ['./feature-composition-form.component.scss'],
  providers: [forwardRefProvider(FeatureCompositionFormComponent)],
})
export class FeatureCompositionFormComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input('features')
  currentFeatures!: Feature[];
  previewFeatures: Platform[];
  value: string[];
  disabled$: BehaviorSubject<boolean>;
  platformService: PlatformService;
  moduleService: ModuleService;
  featureService: FeatureService;
  platforms$: BehaviorSubject<Array<Platform>>;
  changed!: (value: string[]) => void;
  touched!: () => void;

  constructor() {
    this.previewFeatures = [];
    this.value = [];
    this.disabled$ = new BehaviorSubject<boolean>(true);
    this.platformService = inject(PlatformService);
    this.moduleService = inject(ModuleService);
    this.featureService = inject(FeatureService);
    this.platforms$ = new BehaviorSubject<Array<Platform>>([]);
  }

  ngOnInit() {
    this.disabled$.pipe(distinctUntilChanged()).subscribe((disabled) => {
      if (disabled) {
        this.previewCurrentFeatures();
      } else {
        this.platforms$.next([]);
        this.fetchPlatforms();
      }
    });
  }

  ngAfterContentInit() {
    this.previewCurrentFeatures();
  }

  writeValue(features: string[]) {
    this.value = features;
  }

  registerOnChange(fn: (value: string[]) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  fetchPlatforms() {
    const query = { page: 1, size: 12 };
    this.platformService.fetchPlatforms(query).subscribe({
      next: (res) => {
        const platforms = this.previewFeatures.map((platform) => platform.id);
        this.platforms$.next(res.items.map((i) => ({ ...i, hasFeatureSelected: platforms.some((id) => i.id == id), modules: [], active: false })));
      },
    });
  }

  fetchModules(platform: Platform) {
    this.moduleService.fetchModules(platform.id).subscribe({
      next: (res) => {
        const modules = this.previewFeatures.flatMap((platform) => platform.modules?.map((module) => module.id));

        platform.modules = res.map((i) => ({ ...i, hasFeatureSelected: modules.some((id) => i.id === id), features: [], active: false }));
        platform.active = true;
        platform.hasFeatureSelected = platform.modules.some((module) => module.hasFeatureSelected);
      },
    });
  }

  fetchFeatures(module: Module, platform: Platform) {
    this.featureService.fetchFeatures(platform.id, module.id).subscribe({
      next: (res) => {
        module.features = res.map((i) => ({ ...i, selected: this.value.includes(i.id) }));
        module.active = true;
      },
    });
  }

  onClickPlatformItem(platform: Platform) {
    if ((!platform.modules || !platform.modules.length) && !platform.active) {
      this.fetchModules(platform);
      return;
    }

    platform.active = !platform.active;
  }

  onClickModuleItem(platform: Platform, module: Module) {
    if (!module.features || !module.features.length) {
      this.fetchFeatures(module, platform);
      return;
    }

    module.active = !module.active;
  }

  onClickFeatureItem(platform: Platform, module: Module, feature: Feature) {
    if (this.disabled$.value) return;

    feature.selected = !feature.selected;

    if (feature.selected) {
      this.addFeatureToValue(platform, module, feature);
    } else {
      this.remFeatureFromValue(platform, module, feature);
    }
  }

  addFeatureToValue(platform: Platform, module: Module, feature: Feature) {
    this.value = [...this.value, feature.id];
    module.hasFeatureSelected = true;
    platform.hasFeatureSelected = true;
    this.changed(this.value);
  }

  remFeatureFromValue(platform: Platform, module: Module, feature: Feature) {
    this.value = this.value.filter((id) => id !== feature.id);
    module.hasFeatureSelected = module.features?.some(({ selected }) => selected) || false;
    platform.hasFeatureSelected = platform.modules?.some(({ hasFeatureSelected }) => hasFeatureSelected) || false;
    this.changed(this.value);
  }

  previewCurrentFeatures() {
    if (this.previewFeatures.length) {
      this.platforms$.next(this.previewFeatures);
      return;
    }

    const preview: Platform[] = [];

    this.currentFeatures.forEach((feature) => {
      const platform = preview.find((platform) => platform.id === feature.module.platform?.id);

      if (platform) {
        const module = platform.modules?.find((module) => module.id === feature.module?.id);

        if (module) {
          module.features?.push({ ...feature, selected: true });
        } else {
          platform.modules?.push({
            ...feature.module,
            hasFeatureSelected: true,
            features: [{ ...feature, selected: true }],
          });
        }
      } else {
        preview.push({
          ...feature.module.platform,
          hasFeatureSelected: true,
          modules: [
            {
              ...feature.module,
              hasFeatureSelected: true,
              features: [{ ...feature, selected: true }],
            },
          ],
        });
      }
    });

    this.previewFeatures = preview;
    this.platforms$.next(preview);
  }
}

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { AutocompleteItemSource, BreadcrumbItem, ModalComponent, MultipleItem } from '@peca/design-system';
import { ConfirmableActionService } from '../../../../../app/core/services/confirmable-action.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { UserForm } from './users-form';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { Feature } from '../../models/feature.model';

@Component({
  selector: 'peca-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
  standalone: false,
})
export class UsersFormComponent implements OnInit {
  @ViewChild('usersBranchesModal')
  usersBranchesModal!: ModalComponent;
  form: UserForm;
  users: UserService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  confirmable: ConfirmableActionService;
  user$: BehaviorSubject<AsyncState<User>>;
  groups$: BehaviorSubject<MultipleItem[]>;
  breadcrumb: BreadcrumbItem[];
  sellers$: BehaviorSubject<AutocompleteItemSource>;
  branches: { id: string; name: string }[] = [];
  userFeatures: Feature[];

  constructor() {
    this.users = inject(UserService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.confirmable = inject(ConfirmableActionService);
    this.form = new UserForm();
    this.user$ = new BehaviorSubject<AsyncState<User>>({ loading: true });
    this.groups$ = new BehaviorSubject<MultipleItem[]>([]);
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '../../' },
      { label: 'Usuários', path: '../' },
    ];
    this.sellers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.userFeatures = [];
  }

  get userId() {
    const { userId } = this.route.snapshot.params;
    return userId as string;
  }

  get isUpdateOperation() {
    return !!this.userId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      this.fetchUser();
      return;
    }

    this.breadcrumb.push({ label: 'Novo usuário' });
    this.user$.next({ loading: false });
    this.form.group.enable();
  }

  fetchUser() {
    const userId = this.userId;
    this.users.findById(userId).subscribe({
      next: (user: User) => {
        this.form.group.disable();
        this.form.setValue(user);
        this.breadcrumb.push({ label: `${user.firstName} ${user.lastName}` });
        this.user$.next({ loading: false });
      },
      error: () => {
        this.user$.next({ failure: true });
      },
    });
  }

  onClickChangeStatus() {
    const { id, active } = this.form.getValue();
    const firstName = this.form.getValue().firstName;
    const message = `Você está ${active ? 'desativando' : 'ativando'} o usuário "${firstName}".`;

    this.confirmable.confirm(message).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.users.updateStatus(id, !active).subscribe({
        next: () => {
          this.form.controls.active.setValue(!active);
          this.toast.success(`Usuário ${active ? 'desativado' : 'ativado'} com sucesso.`);
        },
        error: () => this.toast.failure(`Falha ao ${active ? 'desativar' : 'ativar'} usuário.`),
      });
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.ngOnInit();
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const value = this.form.getValue();

    this.users.save(value).subscribe({
      next: () => {
        this.toast.success('Usuário salvo com sucesso.');
        this.router.navigate(['usuarios']);
      },
      error: () => {
        this.toast.failure('Falha ao salvar usuário.');
      },
    });
  }
}

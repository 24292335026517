<pds-module-header title="Rastreios">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel>
    <peca-tracking-list-search (search)="onSearch($event)" />

    <div class="tracking-list__content" *ngIf="trackings$ | async; let trackings">
      @if (trackings.data?.length) {
      <pds-table>
        <div class="mb-24 flex">
          <button pdsButton="secondary" (click)="onClickRefresh()" title="Reprocessar">
            <span class="icon">refresh</span>
            <span>Reprocessar</span>
          </button>
        </div>
        <table class="tracking-list__content__table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Comprador</th>
              <th>Canal de Venda</th>
              <th>Data / Hora</th>
              <th>Status</th>
              <th>Transportadora</th>
              <th>Rastreio</th>
              <th>Tempo para entrega</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let tracking of trackings.data; let trackingIndex = index">
              <tr [class]="getRowClass(tracking)" [class.--is-active]="tracking.expanded">
                <td>
                  <a pdsChipLink="terciary" target="_blank" [routerLink]="['/vendas', tracking.orderId]">{{ tracking.commerceReference }}</a>
                </td>
                <td>{{ tracking.buyerName }}</td>
                <td>{{ tracking.salesChannel }}</td>
                <td>{{ tracking.createAt | date : 'dd/MM/yyyy HH:mm' }}</td>
                <td>
                  <span [pdsChip]="tracking.status | shippingStatusColor">{{ tracking.status | shippingStatus }}</span>
                </td>
                <td>
                  <span>{{ tracking.carrierCommercialName }}</span>
                </td>
                <td>
                  @if (tracking.externalReference && tracking.trackingCode) {
                  <a pdsChipLink="terciary" target="_blank" [href]="tracking.trackingCode">{{ tracking.externalReference }}</a>
                  }@else {
                  <span pdsChip>-</span>
                  }
                </td>
                <td>
                  @if(tracking.status === 'DELIVERED') {
                  <span pdsChip="secondary">00:00</span>
                  } @else if(tracking.expectedDelivery && tracking.status !== 'CANCELED' && tracking.status !== 'FAILED' ) {
                  <span pecaTimer [initialTime]="tracking.expectedDelivery" [timerId]="tracking.id"></span>
                  } @else {
                  <span pdsChip>-</span>
                  }
                </td>
                <td pdsColActions>
                  <button pdsButton="icon" title="Detalhes" (click)="onClickExpand(trackingIndex, tracking.id, tracking.orderId)">
                    <span class="icon">{{ tracking.expanded ? 'expand_less' : 'expand_more' }}</span>
                  </button>
                </td>
              </tr>

              <tr [class.--is-expanded]="tracking.expanded" [hidden]="!tracking.expanded">
                <td colspan="9">
                  <div class="tracking-list__details">
                    @if(tracking.expanded && tracking.details) {
                    <peca-tracking-summary [trackingDetails]="tracking.details" />

                    <peca-tracking-details [trackingDetails]="tracking.details" />
                    }
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </pds-table>

      <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />

      } @else if (trackings.loading) {
      <strong>carregando...</strong>
      } @else if (trackings.failure) {
      <strong>Falha ao carregar rastreios.</strong>
      } @else if (trackings.data && !trackings.data.length) {
      <strong>Nenhum rastreio encontrado.</strong>
      }
    </div>
  </pds-panel>
</pds-module-header>

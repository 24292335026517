import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { FormControlName, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'pds-form-field[label]',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  standalone: false,
})
export class FormFieldComponent {
  @Input()
  label: string;

  @Input('errors')
  contextErrors: ValidationErrors | null;

  @Input()
  action: string | undefined;

  @Input()
  icon: string | undefined;

  @Input()
  tip: string | undefined;

  @ContentChild(FormControlName)
  model: FormControlName | undefined;

  @Output()
  clicked: EventEmitter<void>;

  constructor() {
    this.label = '';
    this.contextErrors = null;
    this.action = undefined;
    this.model = undefined;
    this.clicked = new EventEmitter<void>();
  }

  get errors() {
    const errors = { ...(this.model?.errors || {}), ...(this.contextErrors || {}) };
    return Object.keys(errors).length ? errors : null;
  }

  onClickAction() {
    this.clicked.next();
  }
}

<div class="order-search">
  <pds-form [formGroup]="form" (ngSubmit)="onSubmit()">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="ID do Pedido">
        <input type="text" formControlName="search" placeholder="PA-002668" />
      </pds-form-field>
      <pds-form-field label="Comprador">
        <pds-autocomplete placeholder="Nome fantasia ou razão social" formControlName="buyer" [source]="buyers$" (autocomplete)="onAutocompleteBuyers($event)" />
      </pds-form-field>
      <pds-form-field label="Data do pedido">
        <pds-range-form formControlName="date" />
      </pds-form-field>

      <pds-form-field label="Status">
        <pds-select formControlName="status" [options]="statusSource" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="2-1-1">
      @if (salesChannels.length) {
      <pds-form-field label="Canal de Venda">
        <pds-select formControlName="salesChannelId" [options]="salesChannels" />
      </pds-form-field>
      }
      <pds-form-field label="Comprador B2C">
        <input type="text" placeholder="CPF ou CNPJ" formControlName="externalBuyerDocument" />
      </pds-form-field>
      <pds-form-field label="Referência externa">
        <input type="text" placeholder="Insira uma referência externa" formControlName="externalReference" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-actions>
      <button pdsButton="primary" modifier="outlined" (click)="clearFilters()" type="button" [disabled]="isLoading() || form.pristine">Limpar filtros</button>
      <button pdsButton title="Buscar" type="submit" [disabled]="isLoading()">Buscar</button>
    </pds-form-actions>
  </pds-form>
</div>

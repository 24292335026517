import { Component, inject, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { PermissionModuleForm } from './feature-form';
import { Feature } from '../../models/feature.model';
import { FeatureService } from '../../services/feature.service';
import { ModuleService } from '../../services/module.service';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'peca-feature-form',
  templateUrl: './feature-form.component.html',
  styleUrls: ['./feature-form.component.scss'],
  standalone: false,
})
export class FeatureFormComponent implements OnInit {
  form: PermissionModuleForm;
  featureService: FeatureService;
  moduleService: ModuleService;
  platformService: PlatformService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  feature$: BehaviorSubject<AsyncState<Feature>>;
  rolePatternMaskRules = [
    { pattern: /[^a-z\s-]/g, replace: '' },
    { pattern: /\s+/g, replace: '-' },
    { pattern: /-{2,}/g, replace: '-' },
  ];
  breadcrumb: BreadcrumbItem[];

  constructor() {
    this.featureService = inject(FeatureService);
    this.moduleService = inject(ModuleService);
    this.platformService = inject(PlatformService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.form = new PermissionModuleForm();
    this.feature$ = new BehaviorSubject<AsyncState<Feature>>({ loading: true });
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '../../' },
      { label: 'Plataformas', path: '../' },
    ];
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get moduleId() {
    const { moduleId } = this.route.snapshot.params;
    return moduleId as string;
  }

  get featureId() {
    const { featureId } = this.route.snapshot.params;
    return featureId as string;
  }

  get isUpdateOperation() {
    return !!this.featureId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      this.fetchFeature(this.platformId, this.moduleId, this.featureId);
      return;
    }

    this.fetchModule(this.platformId, this.moduleId);
  }

  fetchModule(platformId: string, moduleId: string) {
    const platformDetails = this.platformService.findById(platformId);
    const moduleDetails = this.moduleService.findById(platformId, moduleId);

    forkJoin({ platformDetails, moduleDetails }).subscribe({
      next: ({ platformDetails, moduleDetails }) => {
        this.breadcrumb.push({ label: platformDetails.name, path: `/gestao-de-plataforma/plataformas/${platformId}` });
        this.breadcrumb.push({ label: moduleDetails.name, path: `/gestao-de-plataforma/plataformas/${platformId}/modulos/${moduleId}` });
        this.breadcrumb.push({ label: 'Nova funcionalidade' });
        this.form.group.enable();
        this.feature$.next({ loading: false });
      },
      error: () => {
        this.feature$.next({ failure: true });
      },
    });
  }

  fetchFeature(platformId: string, moduleId: string, featureId: string) {
    const platformDetails = this.platformService.findById(platformId);
    const moduleDetails = this.moduleService.findById(platformId, moduleId);
    const featureDetails = this.featureService.findById(platformId, moduleId, featureId);

    forkJoin({ platformDetails, moduleDetails, featureDetails }).subscribe({
      next: ({ platformDetails, moduleDetails, featureDetails }) => {
        this.breadcrumb.push({ label: platformDetails.name, path: `/gestao-de-plataforma/plataformas/${platformId}` });
        this.breadcrumb.push({ label: moduleDetails.name, path: `/gestao-de-plataforma/plataformas/${platformId}/modulos/${moduleId}` });
        this.breadcrumb.push({ label: featureDetails.role });
        this.form.group.disable();
        this.form.setValue(featureDetails);
        this.feature$.next({ loading: false });
      },
      error: () => {
        this.feature$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
    this.form.controls.role.disable();
  }

  onClickCancel() {
    this.router.navigate(['/gestao-de-plataforma', 'plataformas', this.platformId, 'modulos', this.moduleId]);
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const { id, role, module, description } = this.form.getValue();
    const payload = {
      id,
      role: role.replace(/^-+|-+$/g, ''),
      module,
      description,
    };

    this.featureService.save(payload, this.platformId, this.moduleId).subscribe({
      next: () => {
        this.toast.success('Funcionalidade salva com sucesso.');
        this.router.navigate(['/gestao-de-plataforma', 'plataformas', this.platformId, 'modulos', this.moduleId]);
      },
      error: () => {
        this.toast.failure('Falha ao salvar Funcionalidade.');
      },
    });
  }
}

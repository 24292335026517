import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Validators } from '@peca/commons';

import { User } from '../../models/user.model';
import { UserRequest } from '../../models/user-request.model';
import { Observable, of } from 'rxjs';

type AsyncValidation = (value: unknown) => Observable<ValidationErrors | null>;

export class UserForm {
  readonly group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.cpf]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }),
      birthday: new FormControl('', [Validators.date]),
      roles: new FormControl([]),
      active: new FormControl(true),
      groups: new FormControl([], []),
      sellerSearch: new FormControl(''),
      branches: new FormControl([]),
      systemicUser: new FormControl(false),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      firstName: this.group.get('firstName') as FormControl,
      lastName: this.group.get('lastName') as FormControl,
      document: this.group.get('document') as FormControl,
      email: this.group.get('email') as FormControl,
      birthday: this.group.get('birthday') as FormControl,
      roles: this.group.get('roles') as FormControl,
      active: this.group.get('active') as FormControl,
      groups: this.group.get('groups') as FormControl,
      branches: this.group.get('branches') as FormControl,
      systemicUser: this.group.get('systemicUser') as FormControl,
    };
  }

  get enabled() {
    return this.group.enabled;
  }

  getValue() {
    return {
      id: this.controls.id.getRawValue(),
      firstName: this.controls.firstName.getRawValue(),
      lastName: this.controls.lastName.getRawValue(),
      document: this.controls.document.getRawValue(),
      email: this.controls.email.getRawValue(),
      birthday: this.controls.birthday.getRawValue(),
      roles: this.controls.roles.getRawValue(),
      active: this.controls.active.getRawValue(),
      groups: this.controls.groups.getRawValue(),
      branches: this.controls.branches.getRawValue()?.map((branch: any) => branch.id) || [],
      systemicUser: this.controls.systemicUser.getRawValue(),
    } as UserRequest;
  }

  setValue(value: User) {
    this.controls.id.setValue(value.id);
    this.controls.firstName.setValue(value.firstName);
    this.controls.lastName.setValue(value.lastName);
    this.controls.document.setValue(value.document);
    this.controls.email.setValue(value.email);
    this.controls.birthday.setValue(value.birthday);
    this.controls.roles.setValue(value.roles.map((role) => role.id));
    this.controls.active.setValue(value.active);
    this.controls.groups.setValue(value.groups.map((group) => group.id));
    this.controls.systemicUser.setValue(value.systemicUser);
    this.controls.branches.setValue(value.branches);
  }

  addEmailValidation(validate: AsyncValidation) {
    this.controls.email.addAsyncValidators((control: AbstractControl) => {
      if (!control.value || control.pristine) return of(null);
      return validate(control.value);
    });
  }
}

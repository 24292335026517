import { Routes } from '@angular/router';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductListComponent } from './components/product-list/product-list.component';

export const routes: Routes = [
  {
    path: ':id',
    component: ProductDetailsComponent,
  },
  {
    path: '',
    component: ProductListComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const ProductsModuleLoad = () => import('./products.module').then(({ ProductsModule }) => ProductsModule);

import { BehaviorSubject, forkJoin } from 'rxjs';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { ToastService } from '@peca/commons';
import { PermissionModuleForm } from './module-form';
import { Module } from '../../models/module.model';
import { PlatformService } from '../../services/platform.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'peca-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.scss'],
  standalone: false,
})
export class ModuleFormComponent implements OnInit {
  form: PermissionModuleForm;
  moduleService: ModuleService;
  platformService: PlatformService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  module$: BehaviorSubject<AsyncState<Module>>;
  breadcrumb: BreadcrumbItem[];

  constructor() {
    this.platformService = inject(PlatformService);
    this.moduleService = inject(ModuleService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.form = new PermissionModuleForm();
    this.module$ = new BehaviorSubject<AsyncState<Module>>({ loading: true });
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '../../../../' },
      { label: 'Plataformas', path: '../../../' },
    ];
  }

  get platformId() {
    return this.route.snapshot.params['platformId'] as string;
  }

  get moduleId() {
    return this.route.snapshot.params['moduleId'] as string;
  }

  get isUpdateOperation() {
    return !!this.moduleId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      this.fetchModule(this.platformId, this.moduleId);
      return;
    }

    this.fetchPlatform(this.platformId);
  }

  fetchPlatform(platformId: string) {
    this.platformService.findById(platformId).subscribe({
      next: (platform) => {
        this.breadcrumb.push({ label: platform.name, path: `../${platformId}` });
        this.breadcrumb.push({ label: 'Novo módulo' });
        this.form.group.enable();
        this.module$.next({ loading: false });
      },
      error: () => {
        this.module$.next({ failure: true });
      },
    });
  }

  fetchModule(platformId: string, moduleId: string) {
    const platformDetails = this.platformService.findById(platformId);
    const moduleDetails = this.moduleService.findById(platformId, moduleId);

    forkJoin({ platformDetails: platformDetails, moduleDetails: moduleDetails }).subscribe({
      next: ({ platformDetails, moduleDetails }) => {
        this.breadcrumb.push({ label: platformDetails.name, path: `/gestao-de-plataforma/plataformas/${platformId}` });
        this.breadcrumb.push({ label: moduleDetails.name });
        this.form.group.disable();
        this.module$.next({ loading: false });
        this.form.setValue(moduleDetails);
      },
      error: () => {
        this.module$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.form.reset();
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const payload = this.form.getValue();

    this.moduleService.save(this.platformId, payload).subscribe({
      next: () => {
        this.toast.success('Módulo salvo com sucesso.');
        this.router.navigate(['/gestao-de-plataforma', 'plataformas', this.platformId]);
      },
      error: () => {
        this.toast.failure('Falha ao salvar módulo.');
      },
    });
  }
}

import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from '../../../../environments/environment';
import { VoucherQuery } from '../models/voucher-request.model';
import { Pageable } from '../../../core/models/pageable.model';
import { CreateVoucher, DetailsVoucher, Voucher } from '../models/voucher.model';

@Injectable({ providedIn: 'root' })
export class VoucherService extends BaseHttpClient {
  private readonly resource = `${environment.gateway.url}/backoffice/v1/vouchers`;

  constructor() {
    super();
  }

  fetch(query: VoucherQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<Voucher>>(this.resource, { params });
  }

  fetchDetails(voucherId: number) {
    return this.http.get<DetailsVoucher>(`${this.resource}/${voucherId}`);
  }

  update(voucherId: number, voucher: CreateVoucher) {
    return this.http.patch<Voucher>(`${this.resource}/${voucherId}`, voucher);
  }

  updateStatus(voucherId: number, active: boolean) {
    return this.http.patch(`${this.resource}/${voucherId}/active`, { active });
  }

  publish(voucherId: number) {
    return this.http.patch(`${this.resource}/${voucherId}/publish`, {});
  }

  create(voucher: CreateVoucher) {
    return this.http.post(this.resource, voucher);
  }
}

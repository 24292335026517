import { Routes } from '@angular/router';
import { VouchersListComponent } from './components/vouchers-list/vouchers-list.component';
import { VouchersFormComponent } from './components/vouchers-form/vouchers-form.component';

export const routes: Routes = [
  {
    path: '',
    component: VouchersListComponent,
  },
  {
    path: 'novo',
    component: VouchersFormComponent,
    data: { submitOperation: 'create' },
  },
  {
    path: ':id',
    component: VouchersFormComponent,
    data: { submitOperation: 'update' },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const VouchersModuleLoad = () => import('./vouchers.module').then(({ VouchersModule }) => VouchersModule);

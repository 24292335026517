import { Route } from '@angular/router';
import { PlatformMenuComponent } from './components/platforms-menu/platforms-menu.component';
import { PlatformListComponent } from './components/platform-list/platform-list.component';
import { PlatformFormComponent } from './components/platform-form/platform-form.component';
import { ModuleFormComponent } from './components/modules-form/module-form.component';
import { FeatureFormComponent } from './components/feature-form/feature-form.component';
import { ProfileListComponent } from './components/profile-list/profile-list.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersFormComponent } from './components/users-form/users-form.component';

export const routes: Route[] = [
  {
    path: 'usuarios/novo',
    component: UsersFormComponent,
  },
  {
    path: 'usuarios/:userId',
    component: UsersFormComponent,
  },
  {
    path: 'usuarios',
    component: UsersListComponent,
  },
  {
    path: 'perfis/novo',
    component: ProfileFormComponent,
  },
  {
    path: 'perfis/:profileId',
    component: ProfileFormComponent,
  },
  {
    path: 'perfis',
    component: ProfileListComponent,
  },
  {
    path: 'plataformas/:platformId/modulos/:moduleId/funcionalidades/nova',
    component: FeatureFormComponent,
  },
  {
    path: 'plataformas/:platformId/modulos/:moduleId/funcionalidades/:featureId',
    component: FeatureFormComponent,
  },
  {
    path: 'plataformas/:platformId/modulos/novo',
    component: ModuleFormComponent,
  },
  {
    path: 'plataformas/:platformId/modulos/:moduleId',
    component: ModuleFormComponent,
  },
  {
    path: 'plataformas/nova',
    component: PlatformFormComponent,
  },
  {
    path: 'plataformas/:platformId',
    component: PlatformFormComponent,
  },
  {
    path: 'plataformas',
    component: PlatformListComponent,
  },
  {
    path: '',
    component: PlatformMenuComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const PlatformsModuleLoad = () => import('./platforms.module').then(({ PlatformsModule }) => PlatformsModule);

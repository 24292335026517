import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { Module } from '../../models/module.model';

export class PermissionModuleForm {
  private initialValue: Module | null;
  readonly group: FormGroup;

  constructor() {
    this.initialValue = null;
    this.group = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.length.max(30)]),
      description: new FormControl('', [Validators.required, Validators.length.max(250)]),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      name: this.group.get('name') as FormControl,
      description: this.group.get('description') as FormControl,
    };
  }

  get enabled() {
    return this.group.enabled;
  }

  getValue() {
    return {
      id: this.controls.id.getRawValue(),
      name: this.controls.name.getRawValue(),
      description: this.controls.description.getRawValue(),
    } as Module;
  }

  setValue(value: Module) {
    this.initialValue = value;
    this.controls.id.setValue(value.id);
    this.controls.name.setValue(value.name);
    this.controls.description.setValue(value.description);
  }

  reset() {
    if (!this.initialValue) return;
    this.setValue(this.initialValue);
    this.group.clearValidators();
    this.group.updateValueAndValidity();
    this.group.disable();
  }
}

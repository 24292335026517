import { Component } from '@angular/core';
import { BreadcrumbItem } from '@peca/design-system';

@Component({
  standalone: false,
  selector: 'peca-platforms-menu',
  templateUrl: './platforms-menu.component.html',
  styleUrls: ['./platforms-menu.component.scss'],
})
export class PlatformMenuComponent {
  breadcrumb: BreadcrumbItem[] = [{ label: 'Gestão de plataforma' }];
}

import { BehaviorSubject } from 'rxjs';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '@peca/commons';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { ModuleService } from '../../services/module.service';
import { Module } from '../../models/module.model';

@Component({
  selector: 'peca-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
  standalone: false,
})
export class ModuleListComponent implements OnInit {
  route: ActivatedRoute;
  router: Router;
  service: ModuleService;
  confirmable: ConfirmableActionService;
  toast: ToastService;
  modules$: BehaviorSubject<AsyncState<Module[]>>;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.service = inject(ModuleService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.modules$ = new BehaviorSubject<AsyncState<Module[]>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  ngOnInit() {
    this.fetchModules();
  }

  fetchModules() {
    this.modules$.next({ loading: true });
    this.service.fetchModules(this.platformId).subscribe({
      next: (module) => {
        this.modules$.next({ data: module });
      },
      error: () => {
        this.modules$.next({ failure: true });
      },
    });
  }

  onClickDetails(id: string) {
    this.router.navigate(['/gestao-de-plataforma', 'plataformas', this.platformId, 'modulos', id]);
  }

  onClickRemove(module: Module) {
    const message = `Você está deletando um módulo.`;

    this.confirmable.confirm(message, module, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service.delete(this.platformId, module.id).subscribe({
        next: () => {
          this.toast.success(`Módulo deletado com sucesso.`);
        },
        error: () => {
          this.toast.failure(`Falha ao deletar módulo.`);
        },
      });
    });
  }
}

<pds-module-header title="Perfis" [ctaTemplate]="cta">
  <ng-template #cta>
    <a pdsButton="primary" [routerLink]="['../']">Voltar</a>
  </ng-template>

  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel *ngIf="profile$ | async; let profile">
    <ng-container *ngIf="profile.loading"><strong>carregando...</strong></ng-container>
    <ng-container *ngIf="profile.failure"><strong>Não foi possível carregar os dados do perfil.</strong></ng-container>

    <ng-container *ngIf="!profile.loading && !profile.failure">
      <pds-form [formGroup]="form.group">
        <pds-form-section title="Detalhes">
          <pds-form-row grid="1-2">
            <pds-form-field label="Nome">
              <input type="text" formControlName="name" />
            </pds-form-field>
            <pds-form-field label="Descrição">
              <input type="text" formControlName="description" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Funcionalidades">
          <peca-feature-composition-form [features]="profileFeatures" formControlName="features" />
        </pds-form-section>

        <pds-form-actions>
          <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
            <button pdsButton (click)="onClickEnable()">Editar</button>
          </ng-container>

          <ng-container *ngIf="form.group.enabled">
            <ng-container *ngIf="form.getValue().id && form.group.enabled">
              <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
            </ng-container>
            <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
          </ng-container>
        </pds-form-actions>
      </pds-form>
    </ng-container>
  </pds-panel>
</pds-module-header>

<ul class="platform-list">
  @for (platform of platforms$ | async; track $index) {
  <li class="platform-list__item">
    <div class="platform-list__item__header" role="button" (click)="onClickPlatformItem(platform)">
      <span>
        <h3 class="platform-list__item__title" [class.--has-features]="platform.hasFeatureSelected">{{ platform.name }}</h3>
        <p class="platform-list__item__description">{{ platform.description }}</p>
      </span>
      <span class="icon">{{ platform.active ? 'unfold_less' : 'unfold_more' }}</span>
    </div>

    @if(platform.active && !platform.modules?.length) {
    <strong class="mt-12">Nenhum módulo encontrado para a plataforma {{ platform.name }}</strong>
    }

    <ul [hidden]="!platform.active || !platform.modules?.length" class="module-list">
      @for (module of platform.modules; track $index) {
      <li class="module-list__item">
        <div class="module-list__item__header" role="button" (click)="onClickModuleItem(platform, module)">
          <span>
            <h4 class="module-list__item__title" [class.--has-features]="module.hasFeatureSelected">{{ module.name }}</h4>
            <p class="module-list__item__description">{{ module.description }}</p>
          </span>
          <span class="icon">{{ module.active ? 'unfold_less' : 'unfold_more' }}</span>
        </div>

        <ul [hidden]="!module.active" class="feature-list">
          @for (feature of module.features; track $index) {
          <li class="feature-list__item" role="button" (click)="onClickFeatureItem(platform, module, feature)" [class.--disabled]="this.disabled$ | async">
            <span class="icon" [class.--selected]="feature.selected">{{ feature.selected ? 'check_box' : 'check_box_outline_blank' }}</span>
            <div>
              <h5 class="feature-list__item__title">{{ feature.description }}</h5>
              <p class="feature-list__item__description">{{ feature.role }}</p>
            </div>
          </li>
          }
        </ul>
      </li>
      }
    </ul>
  </li>
  }
</ul>

import { inject, Pipe, PipeTransform } from '@angular/core';
import { Log, LogDirectionType } from '../../models/logs/log.model';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  standalone: true,
  name: 'log',
})
export class LogPipe implements PipeTransform {
  private currencyPipe = inject(CurrencyPipe);

  transform(log: Log, direction: 'from' | 'to') {
    const { changeType } = log;
    let change = undefined;

    switch (changeType) {
      case 'UPDATE_STATUS':
        change = log.internalStatus || log.status;
        break;
      case 'REMOVE_PRODUCT':
      case 'UPDATE_PRODUCT':
        change = log.product;
        break;
      case 'UPDATE_PRICE':
        change = log.finalPrice || log.price;
        break;
      case 'PARTIAL_ORDER':
        change = { from: null, to: log.complementaryOrder };
        break;
      case 'MANUAL_UPDATE_PRICE':
        change = {
          from: this.formatCurrency(log.finalPrice.from),
          to: this.formatCurrency(log.finalPrice.to),
        };
        break;
    }

    if (!change || !change[direction]) return '-' as LogDirectionType;

    return change[direction] as LogDirectionType;
  }

  private formatCurrency(value: any): string {
    if (typeof value !== 'number') return '-';
    return this.currencyPipe.transform(value, 'BRL') || '-';
  }
}

import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: 'input[pdsPatternMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PDSPatternDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class PDSPatternDirective implements OnInit, OnDestroy {
  @Input()
  pdsPatternMask!: { pattern: RegExp; replace: string }[];

  hostElementRef = inject(ElementRef);

  pipe = new CurrencyPipe('pt');

  onChange!: (value: string) => void;

  onTouched!: () => void;

  disabled$ = new BehaviorSubject<boolean>(false);

  renderer = inject(Renderer2);

  destroyRef$ = new Subject<void>();

  get value() {
    return this.hostElementRef.nativeElement.value || '';
  }

  set value(value: string) {
    this.hostElementRef.nativeElement.value = value;
  }

  ngOnInit() {
    this.disabled$.pipe(takeUntil(this.destroyRef$)).subscribe((disabled) => {
      if (disabled) {
        this.renderer.setAttribute(this.hostElementRef.nativeElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeAttribute(this.hostElementRef.nativeElement, 'disabled');
      }
    });
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  @HostListener('input', ['$event'])
  onKeydown() {
    this.value = this.applyPatternMask(this.value);
    this.onChange(this.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.value = this.applyPatternMask(this.value);
    this.onChange(this.value);
    event.preventDefault();
  }

  writeValue(value: string) {
    this.value = this.applyPatternMask(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  applyPatternMask(value: string) {
    return this.pdsPatternMask.reduce((value, rule) => value.replace(rule.pattern, rule.replace), value);
  }
}

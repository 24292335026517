import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Profile } from '../../models/profile.model';
import { Feature } from '../../models/feature.model';

export class ProfileForm {
  readonly group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      features: new FormControl([]),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      name: this.group.get('name') as FormControl,
      description: this.group.get('description') as FormControl,
      features: this.group.get('features') as FormControl,
    };
  }

  get enabled() {
    return this.group.enabled;
  }

  getValue() {
    return {
      id: this.controls.id.getRawValue(),
      name: this.controls.name.getRawValue(),
      description: this.controls.description.getRawValue(),
      features: this.controls.features.getRawValue(),
    } as Profile;
  }

  setValue(value: Profile) {
    this.controls.id.setValue(value.id);
    this.controls.name.setValue(value.name);
    this.controls.description.setValue(value.description);
    this.controls.features.setValue((value.features as Feature[]).map((feature: Feature) => feature.id));
  }
}

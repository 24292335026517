<ng-container *ngIf="modules$ | async; let modules">
  <ng-container *ngIf="modules.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="modules.failure">
    <strong>Não foi possível obter os módulos.</strong>
  </ng-container>

  <ng-container *ngIf="modules.data && !modules.data?.length">
    <strong>Nenhum módulo cadastrado.</strong>
  </ng-container>

  <ng-container *ngIf="modules.data && modules.data?.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let module of modules.data">
            <td>{{ module.name }}</td>
            <td>{{ module.description }}</td>
            <td pdsColActions>
              <button pdsButton="icon" (click)="onClickDetails(module.id)">
                <span class="icon">visibility</span>
              </button>
              <button pdsButton="icon" title="Remover" (click)="onClickRemove(module)">
                <span class="icon">delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </pds-table>
  </ng-container>

  <hr />

  <pds-form-actions>
    <a [routerLink]="['modulos', 'novo']" pdsButton>Adicionar módulo</a>
  </pds-form-actions>
</ng-container>

import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastService, PersistQueryService } from '@peca/commons';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';

import { ConfirmableActionService } from '../../../../../app/core/services/confirmable-action.service';
import { AsyncState } from '../../../../../app/core/models/async-state.model';
import { Pageable } from '../../../../../app/core/models/pageable.model';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { UserQuery } from '../../models/user-query.model';

@Component({
  selector: 'peca-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  standalone: false,
})
export class UsersListComponent implements OnInit {
  router: Router;
  route: ActivatedRoute;
  form: FormGroup;
  users$: BehaviorSubject<AsyncState<Pageable<User>>>;
  service: UserService;
  pagination$: BehaviorSubject<Pagination>;
  lastQuery: UserQuery;
  toast: ToastService;
  confirmable: ConfirmableActionService;
  persistQueryService: PersistQueryService;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de Usuários', path: '/usuarios' }];
  private readonly searchFormInitialValue = {
    search: '',
    active: '',
  };

  constructor() {
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    this.service = inject(UserService);
    this.toast = inject(ToastService);
    this.confirmable = inject(ConfirmableActionService);
    this.persistQueryService = inject(PersistQueryService);
    this.form = new FormGroup({
      search: new FormControl(''),
      active: new FormControl(''),
    });
    this.users$ = new BehaviorSubject<AsyncState<Pageable<User>>>({
      loading: true,
    });
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.lastQuery = { sort: 'audit.createdAt,desc', ...PaginationInitialState };
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, active } = JSON.parse(atob(params['q']));
      this.form.patchValue({
        search: search || '',
        active: active || '',
      });
    });

    const persistedQuery = this.persistQueryService.read<UserQuery>('peca-users-list');
    const query = persistedQuery || this.lastQuery;
    this.fetchUsers(query);
  }

  fetchUsers(query: UserQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-users-list', this.lastQuery);
    this.users$.next({ loading: true });
    this.service.find(query).subscribe({
      next: (data) => {
        this.paginate(data);
        this.users$.next({ data });
      },
      error: () => this.users$.next({ failure: true }),
    });
  }

  paginate(page: Pageable<unknown>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.pagination$.next({ ...pagination, page: pagination.page + 1 });
    this.persistQueryService.rewriteQueryString('peca-users-list', { ...this.lastQuery, ...pagination });
  }

  updateStatus(user: User) {
    this.service.updateStatus(user.id, user.active).subscribe({
      next: () => this.toast.success('Status alterado com sucesso'),
      error: () => {
        user.active = !user.active;
        this.toast.failure('Falha ao alterar o status.');
      },
    });
  }

  onClickDetails(id: number) {
    this.router.navigate(['/usuarios', id]);
  }

  onClickSearch() {
    const query = { ...this.lastQuery, ...this.form.value, page: 0 };
    this.fetchUsers(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onClickSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onChangeB2BStatus(payload: User) {
    const { id, active, firstName } = payload;
    const message = `Você está ${active ? 'desativando' : 'ativando'} o usuário "${firstName}".`;

    this.confirmable.confirm(message).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        payload.active = !active;
        return;
      }

      this.service.updateStatus(id, active).subscribe({
        next: () => {
          this.toast.success(`Usuário ${!active ? 'desativado' : 'ativado'} com sucesso.`);
        },
        error: () => {
          payload.active = !active;
          this.toast.failure(`Falha ao ${!active ? 'desativar' : 'ativar'} usuário.`);
        },
      });
    });
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchUsers(query);
  }
}

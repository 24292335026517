import { FormControl, FormGroup } from '@angular/forms';
import { Feature } from '../../models/feature.model';
import { Validators } from '@peca/commons';
import { Validators as NgValidators } from '@angular/forms';

export class PermissionModuleForm {
  readonly group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      id: new FormControl(''),
      role: new FormControl('', [Validators.required, Validators.length.max(30), NgValidators.pattern(/^[a-z-]+$/)]),
      description: new FormControl('', [Validators.required, Validators.length.max(250)]),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      role: this.group.get('role') as FormControl,
      description: this.group.get('description') as FormControl,
    };
  }

  get enabled() {
    return this.group.enabled;
  }

  getValue() {
    return {
      id: this.controls.id.getRawValue(),
      role: this.controls.role.getRawValue(),
      description: this.controls.description.getRawValue(),
    } as Feature;
  }

  setValue(value: Feature) {
    this.controls.id.setValue(value.id);
    this.controls.role.setValue(value.role);
    this.controls.description.setValue(value.description);
  }
}

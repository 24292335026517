import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Module } from '../models/module.model';

@Injectable({ providedIn: 'root' })
export class ModuleService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2/platforms`;
  }

  fetchModules(platformId: string) {
    return this.http.get<Module[]>(`${this.resource}/${platformId}/modules`);
  }

  findById(platformId: string, moduleId: string): Observable<Module> {
    return this.http.get<Module>(`${this.resource}/${platformId}/modules/${moduleId}`);
  }

  delete(platformId: string, moduleId: string) {
    return this.http.delete(`${this.resource}/${platformId}/modules/${moduleId}`);
  }

  save(platformId: string, module: Module) {
    const payload = {
      name: module.name,
      description: module.description,
    };

    if (module?.id) {
      return this.http.put(`${this.resource}/${platformId}/modules/${module.id}`, payload);
    } else {
      return this.http.post(`${this.resource}/${platformId}/modules`, payload);
    }
  }
}

import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { catchError, map, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Pageable } from '../../../core/models/pageable.model';
import { User } from '../models/user.model';
import { UserQuery } from '../models/user-query.model';
import { UserRequest } from '../models/user-request.model';
import { Role } from '../models/role.model';
import { Group } from 'mixpanel-browser';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/accounts/v1/users`;
  }

  find(query: UserQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<User>>(this.resource, { params });
  }

  findById(id: string) {
    const resource = `${this.resource}/${id}`;
    return this.http.get<User>(resource);
  }

  save(user: UserRequest) {
    if (user.id) {
      return this.update(user);
    } else {
      return this.create(user);
    }
  }

  updateStatus(id: string | number, active: boolean) {
    const resource = `${this.resource}/${id}/active`;
    return this.http.patch(resource, { active });
  }

  validateEmailAvailability(value: string) {
    const resource = `${this.resource}/email/available`;
    const params = { value };

    return this.http.get(resource, { params }).pipe(
      map(() => null),
      catchError((e) => {
        const message = `O e-mail "${value}" já está em uso`;

        if (e.status === 409) {
          return of({ conflicted: { message } });
        }

        // TODO: add observability
        return of(null);
      })
    );
  }

  fetchGroups() {
    const resource = `${environment.gateway.url}/accounts/v1/groups`;
    return this.http.get<Array<Group>>(resource);
  }

  fetchRoles(selecteds: string[]) {
    const resource = `${environment.gateway.url}/accounts/v1/roles`;
    return this.http.get<Array<Role>>(resource).pipe(
      map((roles) => {
        return roles.reduce((acc, role) => {
          const client = role.client;
          acc[client] = acc[client] || [];
          acc[client].push(role);
          return acc;
        }, {} as Record<string, Role[]>);
      }),
      map((clients) => {
        return Object.keys(clients).map((client) => {
          return {
            client,
            modules: clients[client]
              .reduce((acc, role) => {
                const [module] = role.roleName.split('-');
                const group = acc.find((g) => g.module === module);
                if (!group) acc.push({ module: module, roles: [{ ...role, selected: selecteds.includes(role.id) }] });
                else group.roles.push({ ...role, selected: selecteds.includes(role.id) });
                return acc;
              }, [] as Array<{ module: string; roles: Role[] }>)
              .sort((a, b) => a.module.localeCompare(b.module)),
          };
        });
      })
    );
  }

  suggestSellers(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/sellers/suggestions`;
    return this.http.get<any[]>(resource, { params }).pipe(map((items) => items.map(({ id, name }) => ({ id, label: name }))));
  }

  private create(user: UserRequest) {
    return this.http.post(this.resource, user);
  }

  private update(user: UserRequest) {
    const resource = `${this.resource}/${user.id}`;
    return this.http.patch(resource, user);
  }
}

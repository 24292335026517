<pds-module-header title="Usuários" [ctaTemplate]="cta">
  <ng-template #cta>
    <a pdsButton="primary" [routerLink]="['../']">Voltar</a>
  </ng-template>

  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel *ngIf="user$ | async; let user">
    <ng-container *ngIf="user.loading"><strong>carregando...</strong></ng-container>
    <ng-container *ngIf="user.failure"><strong>Não foi possível carregar os dados do usuário.</strong></ng-container>

    <ng-container *ngIf="!user.loading && !user.failure">
      <pds-form [formGroup]="form.group">
        <pds-form-section title="Dados do usuário">
          <pds-form-row grid="1-1-1">
            <pds-form-field label="Nome">
              <input type="text" formControlName="firstName" />
            </pds-form-field>
            <pds-form-field label="Sobrenome">
              <input type="text" formControlName="lastName" />
            </pds-form-field>
            <pds-form-field label="E-mail">
              <input type="text" formControlName="email" pdsMask="email" [alias]="true" />
            </pds-form-field>
          </pds-form-row>
          <pds-form-row grid="1-1-1">
            <pds-form-field label="CPF">
              <input type="text" formControlName="document" pdsMask="999.999.999-99" />
            </pds-form-field>
            <pds-form-field label="Data de nascimento">
              <input type="date" formControlName="birthday" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Controle de acesso">
          <peca-feature-composition-form [features]="userFeatures" formControlName="roles" />
        </pds-form-section>

        <hr />

        <pds-form-actions>
          <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
            <button (click)="onClickChangeStatus()" pdsButton="primary">
              <span>{{ form.getValue().active ? 'Desativar' : 'Ativar' }}</span>
            </button>
            <button pdsButton (click)="onClickEnable()">Editar</button>
          </ng-container>

          <ng-container *ngIf="form.group.enabled">
            <ng-container *ngIf="form.getValue().id && form.group.enabled">
              <button (click)="onClickCancel()" pdsButton="primary">Cancelar</button>
            </ng-container>
            <button pdsButton (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</button>
          </ng-container>
        </pds-form-actions>
      </pds-form>
    </ng-container>
  </pds-panel>
</pds-module-header>

<ng-container *ngIf="features$ | async; let features">
  <ng-container *ngIf="features.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="features.failure">
    <strong>Não foi possível obter as funcionalidades.</strong>
  </ng-container>

  <ng-container *ngIf="features.data && !features.data?.length">
    <strong>Nenhuma funcionalidade cadastrada.</strong>
  </ng-container>

  <ng-container *ngIf="features.data && features.data?.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let feature of features.data">
            <td>{{ feature.role }}</td>
            <td>{{ feature.description }}</td>
            <td pdsColActions>
              <button pdsButton="icon" (click)="onClickDetails(feature.id)">
                <span class="icon">visibility</span>
              </button>
              <button pdsButton="icon" title="Remover" (click)="onClickRemove(feature)">
                <span class="icon">delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </pds-table>

    <hr />
    <pds-form-actions>
      <a [routerLink]="['./funcionalidades/nova']" pdsButton>Adicionar funcionalidade</a>
    </pds-form-actions>
  </ng-container>
</ng-container>

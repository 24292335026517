import { BehaviorSubject } from 'rxjs';
import { Component, inject, OnInit } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Pagination, PaginationInitialState } from '@peca/design-system';
import { AnalyticsService, PersistQueryService } from '@peca/commons';
import { Pageable } from '@peca/backoffice/models/pageable.model';

import { TrackingService } from '../../services/tracking.service';
import { Tracking } from '../../models/tracking.model';
import { TrackingQuery } from '../../models/tracking-query.model';

@Component({
  templateUrl: './tracking-list.component.html',
  styleUrls: ['./tracking-list.component.scss'],
  standalone: false,
})
export class TrackingListComponent implements OnInit {
  trackingService = inject(TrackingService);
  persistQueryService = inject(PersistQueryService);
  analytics = inject(AnalyticsService);
  trackings$ = new BehaviorSubject<AsyncState<Tracking[]>>({ loading: true });
  pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
  lastQuery: TrackingQuery = { ...PaginationInitialState };
  breadcrumb = [{ label: 'Rastreios', path: '/rastreios' }];

  ngOnInit() {
    const persitedQuery = this.persistQueryService.read<TrackingQuery>('peca-tracking-list');
    const query = persitedQuery || this.lastQuery;

    this.analytics.view('rastreios');
    this.fetchTracking(query);
  }

  fetchTracking(query: TrackingQuery): void {
    query.sort = 'created_at,desc';
    const { buyer, ...filteredQuery } = query;

    this.trackingService.fetchTracking(filteredQuery).subscribe({
      next: (trackings) => {
        this.analytics.callback('rastreios_busca', { query, success: true });
        this.paginate(trackings);
        this.trackings$.next({ data: trackings.items });
      },
      error: () => {
        this.analytics.callback('rastreios_busca', { query, success: false });
        this.trackings$.next({ failure: true, loading: false });
      },
      complete: () => {
        this.lastQuery = query;
        this.persistQueryService.rewriteQueryString('peca-tracking-list', query);
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onPaginate(pagination: Pagination): void {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page: page };

    this.fetchTracking(query);
  }

  onClickExpand(trackingIndex: number, trackingId: string, orderId: string): void {
    this.analytics.click('rastreios_expande_detalhes');
    this.trackingService.fetchTrackingDetails(trackingId, orderId).subscribe({
      next: (details) => {
        const newTrackings = this.trackings$.value.data || [];

        newTrackings.forEach((tracking, tI) => {
          if (!(tI === trackingIndex)) {
            tracking.expanded = false;
          }
        });

        newTrackings[trackingIndex].details = details;
        newTrackings[trackingIndex].expanded = !newTrackings[trackingIndex].expanded;

        this.trackings$.next({ data: newTrackings });
      },
    });
  }

  onSearch(trackingQuery: TrackingQuery): void {
    const query = { ...PaginationInitialState, ...trackingQuery };
    const { size } = this.lastQuery;

    if (size) query.size = size;

    this.fetchTracking(query);
  }

  onClickRefresh(): void {
    this.trackings$.next({ loading: true });
    this.fetchTracking(this.lastQuery);
  }

  getRowClass(tracking: Tracking): string {
    const now = new Date().getTime();
    const expectedDelivery = new Date(tracking.expectedDelivery).getTime();
    const timeDifference = expectedDelivery - now;

    if (tracking.status !== 'DELIVERED' && tracking.status !== 'CANCELED' && tracking.status !== 'FAILED') {
      if (timeDifference < 0) {
        return '--is-late';
      } else if (timeDifference <= 31 * 60 * 1000) {
        return '--is-about-to-expire';
      }
    }

    return '';
  }
}

import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { ToastService } from '@peca/commons';
import { FeatureService } from '../../services/feature.service';
import { Feature } from '../../models/feature.model';

@Component({
  selector: 'peca-feature-list',
  templateUrl: './features-list.component.html',
  styleUrls: ['./features-list.component.scss'],
  standalone: false,
})
export class FeatureListComponent implements OnInit {
  route: ActivatedRoute;
  router: Router;
  service: FeatureService;
  confirmable: ConfirmableActionService;
  toast: ToastService;
  features$: BehaviorSubject<AsyncState<Feature[]>>;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.service = inject(FeatureService);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.features$ = new BehaviorSubject<AsyncState<Feature[]>>({ loading: true });
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get moduleId() {
    const { moduleId } = this.route.snapshot.params;
    return moduleId as string;
  }

  ngOnInit() {
    this.fetchFeatures(this.platformId, this.moduleId);
  }

  fetchFeatures(platformId: string, moduleId: string) {
    this.features$.next({ loading: true });
    this.service.fetchFeatures(platformId, moduleId).subscribe({
      next: (features) => {
        this.features$.next({ data: features });
      },
      error: () => {
        this.features$.next({ failure: true });
      },
    });
  }

  onClickDetails(id: string) {
    this.router.navigate(['/gestao-de-plataforma', 'plataformas', this.platformId, 'modulos', this.moduleId, 'funcionalidades', id]);
  }

  onClickRemove(feature: Feature) {
    const message = `Você está deletando uma funcionalidade.`;

    this.confirmable.confirm(message, feature, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.service.delete(this.platformId, this.moduleId, feature.id).subscribe({
        next: () => {
          this.toast.success(`Funcionalidade deletada com sucesso.`);
          this.fetchFeatures(this.platformId, this.moduleId);
        },
        error: () => {
          this.toast.failure(`Falha ao deleta a funcionalidade.`);
        },
      });
    });
  }
}

<pds-module-header title="Gestão de plataforma">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <div class="pds-submenu">
    <div class="pds-submenu__card" role="link" [routerLink]="['./plataformas']" title="Plataformas">
      <h2 class="pds-submenu__card__title">Plataformas</h2>
      <div class="pds-submenu__card__subtitle">Gestão de plataformas, módulos e funcionalidades</div>
    </div>

    <div class="pds-submenu__card" role="link" [routerLink]="['./perfis']" title="Perfis">
      <h2 class="pds-submenu__card__title">Perfis</h2>
      <div class="pds-submenu__card__subtitle">Gestão de perfis de usuário</div>
    </div>

    <div class="pds-submenu__card" role="link" [routerLink]="['./usuarios']" title="Usuários">
      <h2 class="pds-submenu__card__title">Usuários</h2>
      <div class="pds-submenu__card__subtitle">Gestão de usuários</div>
    </div>
  </div>
</pds-module-header>

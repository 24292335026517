import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { environment } from '../../../../environments/environment';
import { Profile } from '../models/profile.model';
import { ProfileQuery } from '../models/profile-query.model';

@Injectable({ providedIn: 'root' })
export class ProfileService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2`;
  }

  fetchProfiles(query: ProfileQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<Profile>>(`${this.resource}/profiles`, { params });
  }

  findById(profileId: string) {
    return this.http.get<Profile>(`${this.resource}/profiles/${profileId}`);
  }

  delete(profileId: string) {
    return this.http.delete(`${this.resource}/profiles/${profileId}`);
  }

  save(profile: Profile) {
    const { name, description, features } = profile;
    const payload = {
      name,
      description,
      features,
    };

    if (profile?.id) {
      return this.http.put(`${this.resource}/profiles/${profile?.id}`, payload);
    } else {
      return this.http.post(`${this.resource}/profiles`, payload);
    }
  }
}

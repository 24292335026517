import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Feature } from '../models/feature.model';

@Injectable({ providedIn: 'root' })
export class FeatureService extends BaseHttpClient {
  private resource: string;

  constructor() {
    super();
    this.resource = `${environment.gateway.url}/backoffice/v2/platforms`;
  }

  fetchFeatures(plataformId: string, moduleId: string) {
    return this.http.get<Feature[]>(`${this.resource}/${plataformId}/modules/${moduleId}/features`);
  }

  findById(plataformId: string, moduleId: string, featureId: string): Observable<Feature> {
    return this.http.get<Feature>(`${this.resource}/${plataformId}/modules/${moduleId}/features/${featureId}`);
  }

  delete(platformId: string, moduleId: string, featureId: string) {
    return this.http.delete(`${this.resource}/${platformId}/modules/${moduleId}/features/${featureId}`);
  }

  updateFeatureState(featureId: string, enabled: boolean) {
    const body = { enabled };
    return this.http.patch(`${this.resource}/${featureId}`, body);
  }

  save(feature: Feature, platformId: string, moduleId: string): Observable<Feature> {
    const payload = {
      description: feature.description,
      role: feature.role,
    };

    if (feature?.id) {
      return this.http.put<Feature>(`${this.resource}/${platformId}/modules/${moduleId}/features/${feature.id}`, payload);
    } else {
      return this.http.post<Feature>(`${this.resource}/${platformId}/modules/${moduleId}/features`, payload);
    }
  }
}

export const menu = {
  enableInsights: true,
  groups: [
    {
      name: 'Cadastros',
      modules: [
        {
          path: 'vendedores',
          label: 'Vendedores',
          icon: 'storefront',
          roles: ['vendedores-listagem'],
        },
        {
          path: 'compradores',
          label: 'Compradores',
          icon: 'shopping_cart',
          roles: ['compradores-listagem'],
        },
        {
          path: 'transportadoras',
          label: 'Transportadoras',
          icon: 'local_shipping',
          roles: ['transportadoras-listagem'],
        },
        {
          path: 'b2b',
          label: 'B2B',
          icon: 'partner_exchange',
          roles: ['b2b-listagem'],
        },
        {
          path: 'cupons',
          label: 'Cupons',
          icon: 'sell',
          roles: ['cupons-listagem'],
        },
        {
          path: 'canais-de-venda',
          label: 'Canais de venda',
          icon: 'real_estate_agent',
          roles: ['canal-de-venda-listagem'],
        },
        {
          path: 'gestao-de-plataforma',
          label: 'Gestao de plataformas',
          icon: 'lock',
          roles: ['plataformas-listagem'],
        },
        {
          path: 'usuarios',
          label: 'Usuários',
          icon: 'person',
          roles: ['usuarios-listagem'],
        },
      ],
    },
    {
      name: 'Operacional',
      modules: [
        {
          path: 'vendas',
          label: 'Vendas',
          icon: 'package_2',
          roles: ['vendas-listagem'],
        },
        {
          path: 'compras',
          label: 'Compras',
          icon: 'local_mall',
          roles: ['compras-listagem'],
        },
        {
          path: 'fretes',
          label: 'Fretes',
          icon: 'transportation',
          roles: ['fretes-listagem'],
        },
        {
          path: 'rastreios',
          label: 'Rastreios',
          icon: 'location_on',
          roles: ['rastreios-listagem'],
        },
        {
          path: 'devolucoes',
          label: 'Devoluções',
          icon: 'cycle',
          roles: ['devolucoes-listagem'],
        },
        {
          path: 'entregas',
          label: 'Entregas',
          icon: 'local_shipping',
          roles: ['entregas-listagem'],
        },
      ],
    },
    {
      name: 'Consultas',
      modules: [
        {
          path: 'produtos',
          label: 'Produtos',
          icon: 'inventory_2',
          roles: ['produtos-consulta'],
        },
      ],
    },
  ],
};

import { BehaviorSubject, Subject } from 'rxjs';
import { BreadcrumbItem } from '@peca/design-system';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { BusinessHours, ToastService } from '@peca/commons';

import { SellerService } from '../../services/seller.service';
import { environment } from '../../../../../environments/environment';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Branch } from '../../models/branch.model';
import { SellerForm } from './seller-form';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-seller-form',
  templateUrl: './seller-form.component.html',
  styleUrls: ['./seller-form.component.scss'],
  standalone: false,
})
export class SellerFormComponent implements OnInit {
  service: SellerService;
  router: Router;
  route: ActivatedRoute;
  toast: ToastService;
  form: SellerForm;
  businessUnit: Array<{ id: string; label: string }>;
  branch$: BehaviorSubject<AsyncState<Branch>>;
  confirmable: ConfirmableActionService;
  breadcrumb: BreadcrumbItem[];
  activityAreas$: BehaviorSubject<{ id: string; name: string }[]>;

  constructor() {
    this.service = inject(SellerService);
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.confirmable = inject(ConfirmableActionService);
    this.form = SellerForm.getInstance();
    this.businessUnit = environment.businessUnit;
    this.branch$ = new BehaviorSubject<AsyncState<Branch>>({ loading: true });
    this.breadcrumb = [{ label: 'Vendedores', path: '/vendedores' }];
    this.activityAreas$ = new BehaviorSubject<{ id: string; name: string }[]>([]);
  }

  get branchId() {
    return this.route.snapshot.params['branchId'] as string;
  }

  get sellerId() {
    return this.route.snapshot.params['sellerId'] as string;
  }

  get isUpdateOperation() {
    const { operation } = this.route.snapshot.data;
    return operation === 'UPDATE_BRANCH';
  }

  get header() {
    const { operation } = this.route.snapshot.data;
    return {
      title: 'Vendedores',
      cta: {
        label: 'Voltar',
        path: operation === 'CREATE_SELLER' ? '/vendedores' : `/vendedores/${this.sellerId}`,
        modifier: 'outlined' as 'outlined' | 'primary',
      },
    };
  }

  ngOnInit() {
    const { operation } = this.route.snapshot.data;
    this.service.fetchActivityAreas().subscribe((data) => this.activityAreas$.next(data));
    switch (operation) {
      case 'CREATE_BRANCH':
        this.initializeCreateBranch();
        break;
      case 'CREATE_SELLER':
        this.initializeCreateSeller();
        break;
      case 'UPDATE_BRANCH':
        this.initializeUpdateBranch();
        break;
    }
  }

  handleBusinessHoursChangeEvent(businessHours: BusinessHours[]) {
    this.form.businessHours = businessHours;
    this.form.update();
  }

  handleSubmitClickEvent() {
    if (this.form.invalid) return;

    const { operation } = this.route.snapshot.data;
    const { branchId } = this.route.snapshot.params;
    const value = this.form.value;

    switch (operation) {
      case 'CREATE_BRANCH':
        this.createBranch(this.sellerId, value);
        break;
      case 'CREATE_SELLER':
        this.createSeller(value);
        break;
      case 'UPDATE_BRANCH':
        this.updateBranch(branchId, value);
        break;
    }
  }

  initializeUpdateBranch() {
    const { sellerId, branchId } = this.route.snapshot.params;
    this.breadcrumb.push({
      label: 'Vendedor',
      path: `/vendedores/${sellerId}`,
    });
    this.breadcrumb.push({ label: 'Ver Filial' });
    this.service.fetchBranchDetails(sellerId, branchId).subscribe((branch) => {
      this.form.value = branch;
      this.branch$.next({ data: branch });
      this.form.disable();
      this.breadcrumb[1].label = branch.seller.name;
      this.breadcrumb[2].label = branch.externalReference;
    });
  }

  initializeCreateSeller() {
    this.branch$.next({ loading: false, failure: false });
    this.breadcrumb.push({ label: 'Novo Vendedor' });
  }

  initializeCreateBranch() {
    const { sellerId } = this.route.snapshot.params;
    this.breadcrumb.push({
      label: 'Vendedor',
      path: `/vendedores/${sellerId}`,
    });
    this.breadcrumb.push({ label: 'Nova Filial' });

    this.service.fetchSellerDetails(sellerId).subscribe((seller) => {
      this.form.seller = seller;
      this.branch$.next({ loading: false, failure: false });
      this.breadcrumb[1].label = seller.name;
    });
  }

  onBlurCnpj() {
    const { operation } = this.route.snapshot.data;
    const { cnpj, cnpjBilling } = this.form.controls;

    if (operation === 'UPDATE_BRANCH' || !cnpjBilling?.pristine) return;

    cnpjBilling?.setValue(cnpj?.value);
  }

  onClickEnable() {
    this.form.enable();
  }

  onClickCancel() {
    this.router.navigate([this.header.cta.path]);
  }

  onClickChangeStatus() {
    this.confirmable.confirm('Você está alterando o status de uma filial.').subscribe(({ confirmed }) => {
      if (!confirmed) return;

      const active = this.form.formGroup.get('active');
      const seller = this.form.value;

      active?.setValue(!seller.active);
      this.service.updateBranchStatus(seller.id, !seller.active).subscribe({
        next: () => this.toast.success('Status alterado com sucesso'),
        error: () => {
          this.toast.success('Falha ao alterar o status.');
          active?.setValue(seller.active);
        },
      });
    });
  }

  private createSeller(branch: Branch) {
    this.service.createSeller(branch).subscribe({
      next: () => {
        this.toast.success('Vendedor criado com sucesso.');
        this.router.navigate(['vendedores']);
      },
      error: () => this.toast.failure('Ocorreu um erro ao criar o vendedor.'),
    });
  }

  private createBranch(sellerId: string, branch: Branch) {
    this.service.createBranch(branch, sellerId).subscribe({
      next: () => {
        this.toast.success('Filial criada com sucesso.');
        this.router.navigate(['vendedores']);
      },
      error: () => this.toast.failure('Ocorreu um erro ao criar o vendedor.'),
    });
  }

  private updateBranch(branchId: string, value: Branch) {
    this.service.updateBranch(branchId, value).subscribe({
      next: () => {
        this.toast.success('Filial atualizada com sucesso.');
        this.router.navigate(['vendedores']);
      },
      error: () => this.toast.failure('Ocorreu um erro ao atualizar a filial.'),
    });
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '../../../../core/models/async-state.model';
import { ProfileService } from '../../services/profile.service';
import { ProfileForm } from './profile-form';
import { Profile } from '../../models/profile.model';
import { Feature } from '../../models/feature.model';

@Component({
  selector: 'peca-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  standalone: false,
})
export class ProfileFormComponent implements OnInit {
  service: ProfileService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  form: ProfileForm;
  profile$: BehaviorSubject<AsyncState<Profile>>;
  breadcrumb: BreadcrumbItem[];
  profileFeatures: Feature[];

  constructor() {
    this.service = inject(ProfileService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.form = new ProfileForm();
    this.profile$ = new BehaviorSubject<AsyncState<Profile>>({ loading: true });
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '../../' },
      { label: 'Perfis', path: '../' },
    ];
    this.profileFeatures = [];
  }

  get profileId() {
    return this.route.snapshot.params['profileId'] as string;
  }

  get isUpdateOperation() {
    return !!this.profileId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      this.fetchProfile(this.profileId);
      return;
    }

    this.breadcrumb.push({ label: 'Novo perfil' });
    this.profile$.next({ loading: false });
    this.form.group.enable();
  }

  fetchProfile(profileId: string) {
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '/gestao-de-plataforma' },
      { label: 'Perfis', path: '/gestao-de-plataforma/perfis' },
    ];
    this.service.findById(profileId).subscribe({
      next: (profile: Profile) => {
        this.form.group.disable();
        this.form.setValue(profile);
        this.profileFeatures = profile.features as Feature[];
        this.breadcrumb.push({ label: profile.name });
        this.profile$.next({ loading: false });
      },
      error: () => {
        this.profile$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
  }

  onClickCancel() {
    this.fetchProfile(this.profileId);
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;
    const payload = this.form.getValue();

    this.service.save(payload).subscribe({
      next: () => {
        this.toast.success('Perfil salvo com sucesso.');
        this.router.navigate(['/gestao-de-plataforma', 'perfis']);
      },
      error: () => {
        this.toast.failure('Falha ao salvar perfil.');
      },
    });
  }
}

<pds-module-header title="Canais de venda" ctaLabel="Voltar" ctaPath="/canais-de-venda" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-form [formGroup]="form">
    <ng-container *ngIf="buyers$ | async; let buyers">
      @if (buyers.loading) {
      <span> carregando formulário... </span>
      }@else if(buyers.failure){
      <peca-retry context="formulário de canais de venda" (retry)="onRetry()" />
      } @else if(buyers.data){

      <pds-form-section title="Identificação">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Canal de venda">
            <input type="text" formControlName="name" placeholder="Nome" />
          </pds-form-field>
          <pds-form-field label="Client ID">
            <input type="text" formControlName="clientId" placeholder="Client ID" pdsMask="store-a{1,32}" style="text-transform: lowercase" />
          </pds-form-field>
          <pds-form-field label="User ID">
            <input type="text" formControlName="userId" placeholder="User ID" pdsMask="a{1,32}" style="text-transform: lowercase" />
          </pds-form-field>
        </pds-form-row>

        <pds-form-row grid="2-1" *ngIf="isEditing">
          <pds-form-field label="Client Secret" action="refresh" (clicked)="onClickRefreshToken()">
            <input [type]="form.enabled ? 'text' : 'password'" readonly formControlName="clientSecret" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Pagamento">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Métodos de pagamento">
            <peca-payment-method-form formControlName="paymentMethodsIds" [multipleFinancing]="true" />
          </pds-form-field>
        </pds-form-row>
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Taxa de conveniência (%)">
            <input type="number" formControlName="convenienceFee" placeholder="Taxa de conveniência em percentual" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Carrinho">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Estratégia de carrinho">
            <select formControlName="applyCartStrategy">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Estratégias" *ngIf="value.applyCartStrategy">
            <pds-multiple-select formControlName="cartStrategy" [source]="cartStrategies$" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Sistema de Busca">
        <pds-form-row grid="1-1-1-1-1">
          <pds-form-field label="Busca por Referência Fraga">
            <select formControlName="searchByFragaId">
              <option value="" disabled>Selecione</option>
              <option [value]="true">Sim</option>
              <option [value]="false">Não</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Exibir Part Number">
            <select formControlName="showPartNumber">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Método de busca">
            <select formControlName="faster">
              <option value="true">Mais rápido</option>
              <option value="false">Mais barato</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Tempo de entrega (horas)">
            <input type="number" formControlName="deliveryTime" />
          </pds-form-field>
          <pds-form-field label="Habilitar Dialect">
            <select formControlName="applyDialect">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Restrições">
        <pds-form-row grid="1-1-1-1">
          <pds-form-field label="Pesquisa por placa">
            <select formControlName="applyDialect">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Visualização da busca">
            <select formControlName="vehicleSearchView">
              <option value="">Selecione</option>
              <option value="COMPLETE">Completa</option>
              <option value="PARTIAL">Parcial</option>
              <option value="BLOCK">Bloqueda</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Limite de busca de placa">
            <input type="number" formControlName="searchLimit" />
          </pds-form-field>
          <pds-form-field label="Compradores">
            <pds-select placeholder="Todos" [options]="buyers.data" formControlName="buyersIds" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Logística">
        <pds-form-row grid="1-1-1-1">
          <pds-form-field label="Tipo de frete">
            <select formControlName="freightType">
              <option value="" disabled>Selecione</option>
              <option value="DROP_SHIPPING">Drop Shipping</option>
              <option value="CROSS_DOCKING">Cross Docking</option>
            </select>
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <hr />
      <pds-form-actions>
        <ng-container *ngIf="isEditing">
          <button (click)="onClickChangeStatus()" pdsButton="primary">
            <span>{{ true ? 'Desativar' : 'Ativar' }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="form.disabled && isEditing">
          <button pdsButton (click)="onClickEdit()">Editar</button>
        </ng-container>
        <ng-container *ngIf="form.enabled && isEditing">
          <button pdsButton="primary" (click)="onClickCancel()">Cancelar</button>
        </ng-container>
        <ng-container *ngIf="form.enabled">
          <button pdsButton type="submit" [disabled]="form.invalid || form.pristine" (click)="onSubmit()">Salvar</button>
        </ng-container>
      </pds-form-actions>
      }
    </ng-container>
  </pds-form>
</pds-module-header>

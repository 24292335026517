import { Injectable } from '@angular/core';
import { AutocompleteItem } from '@peca/design-system';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProductGroup } from '../models/product.group.model';
import { Brand } from '../models/brand.model';

@Injectable({ providedIn: 'root' })
export class ProductAutocompleteService {
  private readonly api = `${environment.gateway.url}/backoffice/v1/products`;
  private readonly brandsHistoryStorage = '@backoffice/autocomplete/brands';
  private readonly groupsHistoryStorage = '@backoffice/autcomplete/groups';

  constructor(private http: HttpClient) {}

  suggestBrands(search: string) {
    const params = { search };
    return this.http.get<Brand[]>(`${this.api}/brands`, { params }).pipe(map<Brand[], AutocompleteItem[]>(this.mapAutocompleteItems));
  }

  suggestGroups(search: string) {
    const params = { search };
    const headers = { 'x-invalidate-cache': 'searchGroups' };
    return this.http.get<ProductGroup[]>(`${this.api}/groups`, { params, headers }).pipe(map<ProductGroup[], AutocompleteItem[]>(this.mapAutocompleteItems));
  }

  storeBrandsHistory(value: AutocompleteItem) {
    return this.storeHistory(value, this.brandsHistoryStorage);
  }

  storeGroupsHistory(value: AutocompleteItem) {
    return this.storeHistory(value, this.groupsHistoryStorage);
  }

  getGroupsHistoryFromStorage() {
    return this.getHistoryFromStorage(this.groupsHistoryStorage);
  }

  getBrandsHistoryFromStorage() {
    return this.getHistoryFromStorage(this.brandsHistoryStorage);
  }

  private mapAutocompleteItems(items: Array<{ id: string; name: string }>) {
    return items.map(({ id, name }) => ({ id, label: name }));
  }

  private getHistoryFromStorage(storage: string) {
    const historyString = sessionStorage.getItem(storage);

    if (!historyString) {
      return [];
    }

    return JSON.parse(historyString) as AutocompleteItem[];
  }

  private storeHistory(value: AutocompleteItem, storage: string) {
    const historyMaxSize = 5;
    let history = this.getHistoryFromStorage(storage);

    if (!history.find(({ id }) => id === value.id)) {
      history = [value, ...history.slice(0, historyMaxSize - 1)];
      sessionStorage.setItem(storage, JSON.stringify(history));
    }

    return history;
  }
}

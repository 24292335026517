<pds-module-header title="Perfis" [ctaTemplate]="cta">
  <ng-template #cta>
    <a pdsButton="primary" [routerLink]="['..']">Voltar</a>
    <a pdsButton="default" [routerLink]="['./novo']">Novo perfil</a>
  </ng-template>

  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel *ngIf="profiles$ | async; let profiles">
    <pds-form [formGroup]="form">
      <pds-form-row grid="1">
        <pds-form-field label="Busca">
          <input type="text" formControlName="search" placeholder="Nome" />
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    <ng-container *ngIf="profiles.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="profiles.failure">
      <strong>Não foi possível obter os perfis.</strong>
    </ng-container>

    <ng-container *ngIf="profiles.data && !profiles.data?.length">
      <strong>Nenhum perfil cadastrado.</strong>
    </ng-container>

    <ng-container *ngIf="profiles.data && profiles.data?.length">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let profile of profiles.data">
              <td>{{ profile.name }}</td>
              <td>{{ profile.description }}</td>
              <td pdsColActions>
                <button pdsButton="icon" (click)="onClickDetails(profile.id)">
                  <span class="icon">visibility</span>
                </button>
                <button pdsButton="icon" title="Remover" (click)="onClickRemove(profile)">
                  <span class="icon">delete</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)"></pds-pagination>
      </pds-table>
    </ng-container>
  </pds-panel>
</pds-module-header>

<pds-module-header [title]="header.title" [ctaLabel]="header.cta.label" [ctaPath]="header.cta.path" [modifier]="header.cta.modifier">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <div class="seller-list__content">
    <ng-container *ngIf="branch$ | async; let branch">
      <pds-panel>
        <ng-container *ngIf="branch.loading || branch.failure; else formTemplate">
          <ng-container *ngIf="branch.loading">
            <span>carregando...</span>
          </ng-container>

          <ng-container *ngIf="branch.failure">
            <strong>Não foi possível listar os vendedores. Por favor, tente novamente mais tarde.</strong>
          </ng-container>
        </ng-container>
      </pds-panel>
    </ng-container>
  </div>
</pds-module-header>

<ng-template #formTemplate>
  <div class="seller-form">
    <pds-alert *ngIf="!form.value.active" title="Vendedor desativado" icon="warning" type="warning">
      <span
        >O vendedor <strong>{{ form.value.commercialName }}</strong> está desativado.</span
      >
    </pds-alert>
    <pds-form [formGroup]="form.formGroup">
      <pds-form-section title="Empresa" formGroupName="seller">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Nome">
            <input type="text" formControlName="name" placeholder="Nome da empresa" />
          </pds-form-field>

          <pds-form-field label="Referência Fraga">
            <input type="text" formControlName="fragaReference" placeholder="Referência fraga" maxlength="36" />
          </pds-form-field>

          <pds-form-field label="E-mail">
            <input type="text" formControlName="email" placeholder="E-mail para contato" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Filial">
        <pds-form-row grid="1-1-1">
          <pds-form-field label="Código">
            <input type="text" formControlName="externalReference" placeholder="Código da filial" [pdsFormImmutable]="isUpdateOperation" />
          </pds-form-field>

          <pds-form-field label="Unidade de negócio">
            <select formControlName="businessUnit">
              <option value="">Selecione</option>
              <option value="SP">SP</option>
              <option value="MG">MG</option>
            </select>
          </pds-form-field>

          <pds-form-field label="Área de atuação">
            <select formControlName="activityAreaId">
              <option value="">Selecione</option>
              @for (area of activityAreas$| async; track $index) {
              <option [value]="area.id">{{ area.name }}</option>
              }
            </select>
          </pds-form-field>

          <pds-form-field label="Nome Comercial">
            <input type="text" formControlName="commercialName" placeholder="Nome comercial" />
          </pds-form-field>

          <pds-form-field label="CNPJ">
            <input type="text" formControlName="cnpj" (blur)="onBlurCnpj()" placeholder="CNPJ" pdsMask="99.999.999/9999-99" />
          </pds-form-field>

          <pds-form-field label="Razão Social">
            <input type="text" formControlName="companyName" placeholder="Razão Social" />
          </pds-form-field>

          <pds-form-field label="Nome Fantasia">
            <input type="text" formControlName="tradingName" placeholder="Nome fantasia" />
          </pds-form-field>

          <pds-form-field label="E-mail">
            <input type="text" formControlName="email" placeholder="E-mail para contato" />
          </pds-form-field>

          <pds-form-field label="Descrição">
            <input type="text" formControlName="description" placeholder="Descrição" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Configurações">
        <pds-form-row grid="1-1-1-1">
          <pds-form-field label="Modelo de Venda">
            <select formControlName="salesModel">
              <option value="1P">1P</option>
              <option value="3P">3P</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Tempo de Separação (minutos)">
            <input type="number" formControlName="pickingTime" placeholder="Tempo de separação" />
          </pds-form-field>

          <pds-form-field label="Valor Mínimo do Pedido (R$)">
            <input type="text" formControlName="minimumOrderValue" placeholder="Valor mínimo do pedido" pdsCurrencyMask />
          </pds-form-field>

          <pds-form-field label="Taxa de Conveniência (%)">
            <input type="text" formControlName="convenienceFee" placeholder="Taxa de conveniência" pdsNumberMask />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Faturamento">
        @if (form.controls.cnpj?.value !== form.controls.cnpjBilling?.value) {
        <pds-alert title="CNPJ de faturamento divergente do CNPJ da filial" icon="warning" type="warning">
          <span>Atenção! O faturamento será realizado neste CNPJ, que não corresponde ao CNPJ da Filial. Certifique-se que está informação esta correta antes de continuar.</span>
        </pds-alert>
        }
        <pds-form-row grid="1-1-1-1">
          <pds-form-field label="CNPJ de faturamento" tip="CNPJ utilizado para faturar o pedido de compra.">
            <input type="text" pdsMask="99.999.999/9999-99" formControlName="cnpjBilling" placeholder="Cnpj de faturamento" />
          </pds-form-field>
          <pds-form-field label="Tipo de faturamento">
            <select formControlName="billingType">
              <option value="">Selecione</option>
              <option value="DAILY">Diário</option>
              <option value="WEEKLY">Semanal</option>
              <option value="BIWEEKLY">Quinzenal</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Método de pagamento">
            <select formControlName="billingMethodId">
              <option value="">Selecione</option>
              <option value="3">Boleto</option>
              <option value="1">PIX</option>
            </select>
          </pds-form-field>
          <pds-form-field label="Prazo para pagamento (dias)">
            <input type="text" formControlName="paymentDeadlineInDays" placeholder="Prazo para pagamento" />
          </pds-form-field>
        </pds-form-row>
      </pds-form-section>

      <pds-form-section title="Endereço">
        <peca-address formControlName="address" />
      </pds-form-section>

      <pds-form-section title="Responsável">
        <peca-responsible-form formControlName="responsible" />
      </pds-form-section>

      <pds-form-section title="Horário de funcionamento">
        <peca-business-hours [value]="form.value.businessHours" [disabled]="form.formGroup.disabled" (changes)="handleBusinessHoursChangeEvent($event)"></peca-business-hours>
      </pds-form-section>
    </pds-form>

    <hr />

    <pds-form-actions>
      <ng-container *ngIf="isUpdateOperation">
        <ng-container *ngIf="form.formGroup.disabled">
          <button pdsButton="primary" (click)="onClickChangeStatus()">
            {{ form.value.active ? 'Desativar' : 'Ativar' }}
          </button>
          <button pdsButton (click)="onClickEnable()">Editar</button>
        </ng-container>

        <ng-container *ngIf="form.formGroup.enabled">
          <button pdsButton="primary" (click)="onClickCancel()">cancelar</button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="form.formGroup.enabled">
        <button pdsButton type="submit" (click)="handleSubmitClickEvent()" [disabled]="form.formGroup.pristine || form.formGroup.invalid">Salvar</button>
      </ng-container>
    </pds-form-actions>
  </div>
</ng-template>

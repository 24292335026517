import { Route } from '@angular/router';
import { B2bListComponent } from './components/b2b-list/b2b-list.component';
import { B2bFormComponent } from './components/b2b-form/b2b-form.component';

export const routes: Route[] = [
  {
    path: 'novo',
    component: B2bFormComponent,
    data: { update: false },
  },
  {
    path: ':b2bId',
    component: B2bFormComponent,
    data: { update: true },
  },
  {
    path: '',
    component: B2bListComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const B2bModuleLoad = () => import('./b2b.module').then(({ B2bModule }) => B2bModule);

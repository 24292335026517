import { Route } from '@angular/router';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersFormComponent } from './components/users-form/users-form.component';

export const routes: Route[] = [
  {
    path: 'novo',
    component: UsersFormComponent,
  },
  {
    path: ':userId',
    component: UsersFormComponent,
  },
  {
    path: '',
    component: UsersListComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const UsersModuleLoad = () => import('./users.module').then(({ UsersModule }) => UsersModule);

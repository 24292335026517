import { Component, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@peca/commons';
import { BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '../../../../core/models/async-state.model';
import { PlatformService } from '../../services/platform.service';
import { PlatformForm } from './platform-form';
import { Platform } from '../../models/platform.model';

@Component({
  selector: 'peca-platform-form',
  templateUrl: './platform-form.component.html',
  styleUrls: ['./platform-form.component.scss'],
  standalone: false,
})
export class PlatformFormComponent implements OnInit {
  form: PlatformForm;
  service: PlatformService;
  route: ActivatedRoute;
  toast: ToastService;
  router: Router;
  platform$: BehaviorSubject<AsyncState<Platform>>;
  rolePatternMaskRules = [
    { pattern: /[^a-z\s-]/g, replace: '' },
    { pattern: /\s+/g, replace: '-' },
    { pattern: /-{2,}/g, replace: '-' },
  ];
  breadcrumb: BreadcrumbItem[];

  constructor() {
    this.service = inject(PlatformService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.form = new PlatformForm();
    this.platform$ = new BehaviorSubject<AsyncState<Platform>>({ loading: true });
    this.breadcrumb = [
      { label: 'Gestão de plataforma', path: '../../' },
      { label: 'Plataformas', path: '../' },
    ];
  }

  get platformId() {
    const { platformId } = this.route.snapshot.params;
    return platformId as string;
  }

  get isUpdateOperation() {
    return !!this.platformId;
  }

  ngOnInit() {
    if (this.isUpdateOperation) {
      const platformId = this.platformId;
      this.fetchPlatform(platformId);
      return;
    }

    this.breadcrumb.push({ label: 'Nova' });
    this.platform$.next({ loading: false });
    this.form.group.enable();
  }

  fetchPlatform(platformId: string) {
    this.service.findById(platformId).subscribe({
      next: (platform: Platform) => {
        this.breadcrumb.push({ label: platform.name });
        this.form.group.disable();
        this.form.setValue(platform);
        this.platform$.next({ loading: false });
      },
      error: () => {
        this.platform$.next({ failure: true });
      },
    });
  }

  onClickEnable() {
    this.form.group.enable();
    this.form.controls.uniqueName.disable();
  }

  onClickCancel() {
    this.ngOnInit();
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const { id, name, description, uniqueName } = this.form.getValue();
    const payload = {
      id,
      name,
      description,
      uniqueName: uniqueName.replace(/^-+|-+$/g, ''),
    };

    this.service.save(payload, this.platformId).subscribe({
      next: () => {
        this.toast.success('Plataforma salva com sucesso.');
        this.router.navigate(['/gestao-de-plataforma', 'plataformas']);
      },
      error: () => {
        this.toast.failure('Falha ao salvar plataforma.');
      },
    });
  }
}
